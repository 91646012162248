import { ElementRef } from "@angular/core";
import { AbstractControl, AsyncValidatorFn, FormArray, FormControl, FormGroup, ValidatorFn } from "@angular/forms";
import { ArithmenticDateInterface, DateTodayInterface, DesingationAccessInterface, DesignationInterface, LoginResponse, PurchaseRequestInterface, UserInfo_LoginLocalStorageInterface, UserInfo_AllInfoInterface } from "src/app/shared/interface/global-interface";
import { PromptMessage } from "../Class/prompt-message-class";
import { HttpErrorResponse } from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";
import { actionIdList, userCan } from "./access.methods";
import { of, startWith } from 'rxjs';

export class Helper {
  private constructor() { }

  // ***************************
  // *         PUBLIC          *
  // ***************************

  /**
   * Get the `id` value in search param.
   * 
   * Return `-1` if no value found.
   */
  public static getIdOnSearchParam(aRoute?: ActivatedRoute | null, key: string = 'id') {
    if (!aRoute) return -1;
    return Number(aRoute.snapshot.paramMap.get(key) ?? -1);
  }

  public static can(actionName: string) {
    return userCan(actionName, Helper.UserLoginData().Access);
  }

  public static get accessList() {
    return actionIdList;
  }

  public static searchAccessByActionName(actionName: string, selectedUser?: UserInfo_AllInfoInterface) {
    const [module, aaccess] = actionName.replace("m", "").split("-").map(v => Number(v));
    if (selectedUser) return selectedUser.Access[module - 1].Actions[aaccess - 1];
    else return Helper.UserLoginData().Access[module - 1].Actions[aaccess - 1];
  }

  public static searchAccessByActionName_AccessOnly(actionName: string, selectedUserAccess: { Actions: number[] }[]) {
    const [module, aaccess] = actionName.replace("m", "").split("-").map(v => Number(v));
    if (selectedUserAccess) return selectedUserAccess[module - 1].Actions[aaccess - 1];
    else return Helper.UserLoginData().Access[module - 1].Actions[aaccess - 1];
  }

  public static escapeString(str: string) {
    return (str + '').replace(/[\\"']/g, '\\$&').replace(/\u0000/g, '\\0');
  }

  public static employeeIsSecuritGuard(designationIdOfSelectedEmployee: number) {
    return designationIdOfSelectedEmployee == 12;
  }

  public static employeeIsAdmin(designationIdOfSelectedEmployee: number) {
    return designationIdOfSelectedEmployee == 9;
  }

  public static idFormatter(id?: number | string | null, length?: number | null, start?: string | null) {
    if (!id) return '';
    if (!length) return '';
    if (!start) return '';
    return `${start}-${String(id).padStart(length, '0')}`
  }

  public static formattedIdToNumber(formattedId?: string | null): number {
    if (!formattedId) return -1;
    const [start, id] = formattedId.split('-');
    if (!start) return -1;
    if (!id) return -1;
    return Number(id);
  }

  public static itemInArr1ExistInArr2(arr1?: any[] | null, arr2?: any[] | null) {
    if (!arr1) return false;
    if (!arr2) return false;

    const set1 = new Set(arr1);

    return arr2.some(element => set1.has(element));
  }

  public static arrHasDuplicateValues(arr: any) {
    const uniqueValues = new Set(arr);
    return uniqueValues.size !== arr.length;
  }


  public static date = {

    today: new Date(),

    addDay: (date: Date, daysToAdd: number) => {

      const newDate = new Date(date);

      newDate.setDate(date.getDate() + daysToAdd);

      return newDate;
    },

    deductDay: (date: Date, daysToDeduct: number) => {

      const newDate = new Date(date);

      newDate.setDate(date.getDate() - daysToDeduct);

      return newDate;
    },
  }

  public static cutString(originalString: string, maxCharacter: number) {
    return originalString.slice(0, maxCharacter);
  }

  public static Array_getLast<T>(array: T[]) {
    return array[array.length - 1];
  }

  public static get getURLData(): {
    protocol?: string;
    hostname?: string;
    port?: string;
    pathname?: string;
    searchParams?: URLSearchParams;
  } {
    const url = new URL(window.location.href);

    return {
      protocol: url.protocol, // e.g., "https:"
      hostname: url.hostname, // e.g., "www.example.com"
      port: url.port ? url.port : undefined, // Only include port if it exists
      pathname: url.pathname, // e.g., "/path/to/resource"
      searchParams: url.searchParams, // Provides access to query string parameters
    };
  }

  /**
   * return the number inside parentheses ex. "(123)" => 123.
   * 
   * return -1 if nothing found.
   */
  public static extractNumbersInParentheses(input?: string | null): number {
    if (!input) return -1;
    const match = input.match(/\((\d+)\)/);
    if (match && match[1]) {
      return parseInt(match[1], 10);
    } 1
    return -1;
  }

  public static redirect(path: string) {
    // location.href = `${Helper.getURLData.protocol}${Helper.getURLData.hostname}:${Helper.getURLData.port}${path}`;
    location.href = `${path}`;
  }

  public static handleApiError(err: any, prompt: PromptMessage) {
    const getErrorMessage = (error: any) => {
      if (error.errors) return Helper.replaceAll((error.errors as string[]).toString(), ',', ', ');
      if (error.message) return error.message;
      return 'Server error.';
    }

    const error = (err as HttpErrorResponse).error;
    const errorMessage = getErrorMessage(error);
    prompt?.set(Helper.promptErrorWithMessage(errorMessage), 1);
    return of(undefined);
  }

  public static promptErrorWithMessage(HttpResponseBody: any) {
    return `${Helper.promptError}${HttpResponseBody.message ? ': ' + HttpResponseBody.message : ''}`;
  }

  public static get noAccess() {
    return 'Oops! You do not have access to this feature.';
  }

  public static get promptError() {
    return 'Error occurred. Try again later';
  }

  public static get promptRequiredFields() {
    return 'Please fill out required fields.';
  }

  public static get dateToday() {
    return new Date();
  }

  public static ToInputTime(timeString: string) {
    if (!timeString) return '';
    const date = new Date(`${new Date().toLocaleDateString()} ${timeString}`);
    const time = {
      h: date.getHours().toString().padStart(2, '0'),
      m: date.getMinutes().toString().padStart(2, '0')
    };
    return `${time.h}:${time.m}`;
  }

  /**
   * returns date in format of `MM/DD/YYYY at 00:00:00 pm/am`
   */
  public static Date_ToGeneralUnderstandableDateFormatv2(date: string) {
    if (!date) return 'No Date';
    return `${new Date(date).toDateString()} at ${new Date(date).toLocaleTimeString()}`
  }

  /**
   * returns date in format of `Day Mon YYYY  at 00:00:00 pm/am`
   */
  public static Date_ToGeneralUnderstandableDateFormat(date: string) {
    if (!date) return 'No Date';
    return `${new Date(date).toLocaleDateString()} at ${new Date(date).toLocaleTimeString()}`
  }

  public static Str_ToUpperFirst(str: string): string {
    const firstLetterUpper = str.charAt(0).toUpperCase();
    const remainingString = str.slice(1);
    return firstLetterUpper + remainingString;
  }

  public static ReplaceLayout(htmlLayout: string, replace: Object): string {
    Helper.ObjectToArray(replace).forEach(v => htmlLayout = htmlLayout.replace(`{{${v.key}}}`, v.value));
    return htmlLayout;
  }

  /**
   * Convert Object into array {key, value}[]
   */
  public static ObjectToArray(obj?: Object | null) {
    if (!obj) return [] as any[];
    return Object.entries(obj).map(([key, value]) => ({ key, value }));
  }

  /**
   * For Government Contribution only!
   */
  public static Array_SortRange(arr: any[], key: string): any[] {
    return arr.sort((obj1, obj2) => {
      if (String(obj1[key].From).toLowerCase() === "below") {
        return -1;
      } else if (String(obj1[key].To).toLowerCase() === "above") {
        return 1000;
      } else if (String(obj2[key].From).toLowerCase() === "below") {
        return 1;
      } else if (String(obj2[key].To).toLowerCase() === "above") {
        return -1000;
      } else if (Helper.isNumeric(obj1[key].From)) {
        return parseInt(obj1[key].From) - parseInt(obj2[key].From);
      } else {
        return obj1[key].From.localeCompare(obj2[key].From);
      }
    });
  }

  /**
   * Make an array of numbers
   */
  public static MakeArrayNumber(from: number, to: number): Array<number> {
    from--;
    const length = to - from;
    return Array(length).fill(0).map((_, i) => (from + i + 1));
  }

  /**
   * Sort array of numbers. Pass `'1'` for ascending and `'-1'` for descending.
   */
  public static ArrayNum_Sort(array: Array<number>, order: number): Array<number> {
    return array.sort((a, b) => (order > 1) ? (a - b) : (b - a));
  }

  /**
   * Remove specific element in an array
   */
  public static Arrray_RemoveOn(array: any[], index: number): any[] {
    array.splice(index, 1);
    return array;
  }

  /**
   * Check wether the `string` is `numeric`
   */
  public static isNumeric(str: String) {
    return !isNaN(Number(str));
  }

  /**
   * Generate a `random string`
   */
  public static MakeRandomString(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  /**
   * Clear the fields of the `FormGroup`
   */
  public static FormClear(form: FormGroup) {
    form.reset();
  }

  public static FormControl_f(name: string, selector?: string): HTMLElement {
    return document.querySelector(`input[formControlName="${name}"]${selector}`) as HTMLElement;
  }

  /**
   * Trigger the close of the modal component
   */
  public static ModalClose(closeButtonRef?: ElementRef | null) {
    if (!closeButtonRef) {
      console.warn("Modal close button reference is not defined: ", closeButtonRef);
      return;
    };
    closeButtonRef.nativeElement.click();
  }

  /**
   * Check wether the current user has `access to view other employee`.
   */
  public static User_EmployeesAccess(): boolean {
    return this.User_Designation() == 9 || this.User_Designation() == 13;
  }

  /**
   * Check wether the `user id` passed is a `valid user`.
   */
  public static InvalidUsers(id: number): boolean {
    id = Number(id);
    return [128, 129].includes(id);
  }

  /**
   * Get the current user `Designation ID`
   */
  public static User_Designation(): number {
    return Number(localStorage.getItem('designation') ?? '-1');
  }

  /**
   * Returns `1` if admin, `0` if not.
   * @returns number
   */
  public static User_Access(): number {
    return Number(localStorage.getItem('loginaccess') ?? '0');
  }

  /**
   *  Check wether the current user has access to `DTR Management`
   */
  public static User_DTRManager(newValue: number = -1): boolean {
    if (newValue === -1) return Number(localStorage.getItem('DTRManagement') ?? -1) === 1;
    localStorage.setItem('DTRManagement', newValue === 1 ? '1' : '0');
    return Number(localStorage.getItem('DTRManagement') ?? -1) === 1;
  }

  /**
   *  Get the `ID` of current user
   */
  public static User_ID(): number {
    return Number(localStorage.getItem('loginid') ?? -1);
  }

  public static userIsClient() {
    const isClient = localStorage.getItem('client');
    if (isClient == null || isClient == undefined) return false;
    return isClient == "1";
  }

  public static UserLoginData_InitAsClient(body: LoginResponse, clientAccess: DesingationAccessInterface[]) {
    localStorage.setItem('userName', body.UserName ?? '');
    localStorage.setItem('logintime', Date());
    localStorage.setItem('loginaccess', JSON.stringify(clientAccess));
    localStorage.setItem('loginid', String(body.ID));
    localStorage.setItem('secured', '1');
    localStorage.setItem('client', '1');
  }

  public static UserLoginData_Init(body: any): void {
    localStorage.setItem('logintime', Date());
    localStorage.setItem('loginaccess', JSON.stringify(body.Access));
    localStorage.setItem('loginid', String(body.user.ID));
    localStorage.setItem('FirstName', body.user.FirstName);
    localStorage.setItem('LastName', body.user.LastName);
    localStorage.setItem('userName', body.user.UserName);
    localStorage.setItem('DTRManagement', String(body.user.DTRManagement));
    localStorage.setItem('designation', String(body.user.Designation.ID));
    localStorage.setItem('profpic', String(body.user.ProfilePic));
    localStorage.setItem('VL', String(body.remaining_leaves.VL));
    localStorage.setItem('SL', String(body.remaining_leaves.SL));
    localStorage.setItem('assignment', body.Project ?? '');
    localStorage.setItem('secured', String(body.user.passwordSet));
    localStorage.setItem('Birthday', String(body.Birthday));
    localStorage.setItem('Department', String(body.user.Department.ID));
  }

  /**
   *  Get `all the necessary data` of current user
   */
  public static UserLoginData(): UserInfo_LoginLocalStorageInterface {
    return <UserInfo_LoginLocalStorageInterface>{
      ID: Number(localStorage.getItem('loginid') ?? -1),
      FirstName: localStorage.getItem('FirstName') ?? '',
      LastName: localStorage.getItem('LastName') ?? '',
      Token: localStorage.getItem('LoginData') ?? '',
      Access: JSON.parse(localStorage.getItem('loginaccess') ?? '[]') as DesingationAccessInterface[],
      Time: localStorage.getItem('logintime') ?? '',
      UserName: localStorage.getItem('userName') ?? '',
      Designation: localStorage.getItem('designation') ?? '',
      DTRManagement: Number(localStorage.getItem('DTRManagement') ?? -1),
      ProfPic: String(localStorage.getItem('profpic') ?? ''),
      Assignment: String(localStorage.getItem('assignment') ?? ''),
      SL: String(localStorage.getItem('SL') ?? ''),
      VL: String(localStorage.getItem('VL') ?? ''),
      Secured: String(localStorage.getItem('secured') ?? '') === '1',
      Birthday: String(localStorage.getItem('Birthday') ?? ''),
      Department: String(localStorage.getItem('Department') ?? ''),
    }
  }

  /**
   *  Get selected `Asset ID` of the current user
   */
  public static User_SelectedAssetID(newIDValue: number = -1): number {
    if (newIDValue > 0) localStorage.setItem('selected_asset', String(newIDValue));
    return Number(localStorage.getItem('selected_asset') ?? -1);
  }

  /**
   * Get all the invalid in `FormGroup`
   */
  public static GetInvalids(form?: FormGroup, debugMode = false): { control: FormControl, index: string }[] {
    if (!form) return [];

    // Initialize an empty array object to store all the errors 
    const errors: { control: FormControl, index: string }[] = [];

    // Iterate to each AbstractControl in the FormGroup
    for (const formControlIndex in form.controls) {

      // Convert AbstractControl into FormControl
      const formControl = form.controls[formControlIndex];

      // Check if the FormControl is an instance of FormArray
      if (formControl instanceof FormArray) {

        // Iterate to each FormGroup in FormArray
        for (const formGroup of formControl.controls as FormGroup[]) {

          // Transform each AbstractControl into FormGroup and perform a recursive to validate each FormCntrol in FormGroup
          Helper.GetInvalids(formGroup).forEach(v => errors.push(v));
        }

        // Return to start of loop if AbstractControl is not an Invalid FormControl 
        continue;
      }

      // Check if FormControl is invalid
      if (formControl.invalid) {

        // Mark the FormControl as Invalid Value
        formControl.markAsTouched();

        // Push the invalid FormControl in out error holder
        errors.push({
          control: formControl as FormControl,
          index: formControlIndex
        });
      }
    }

    // Debugmode is on
    if (debugMode) console.warn("Validation: ", { errors });

    // Return the errors gathered
    return errors;
  }

  public static enableEachControlInFormGroup(form: FormGroup) {
    for (const formControlIndex in form.controls) {
      const formControl = form.controls[formControlIndex];
      if (formControl instanceof FormArray) {
        for (const formGroup of formControl.controls as FormGroup[]) {
          Helper.enableEachControlInFormGroup(formGroup);
        }
      } else {
        formControl.enable();
      }
    }
  }

  public static disableEachControlInFormGroup(form: FormGroup) {
    for (const formControlIndex in form.controls) {
      const formControl = form.controls[formControlIndex];
      if (formControl instanceof FormArray) {
        for (const formGroup of formControl.controls as FormGroup[]) {
          Helper.disableEachControlInFormGroup(formGroup);
        }
      } else {
        formControl.disable();
      }
    }
  }

  public static strRemoveVowel(str?: string | null) {
    if (!str) return '';
    const regex = /[aeiouAEIOU\s]/g;
    return str.replace(regex, '');
  }

  public static transform = {
    profileGoventmentMode: (Mode: string) => {
      if (Mode == '0') return 'Not applicable';
      if (Mode == '1') return 'Every  1st - 15th of month';
      if (Mode == '2') return 'Every 16th - 30th of month';
      return 'No Value';
    }
  }

  public static toNumeric(value: any) {
    const numericRegex = /[\d.\-\+\/*]/g;
    const numericString = String(value).match(numericRegex)?.join('') || '';
    return Number(numericString);
  }


  public static isNumber(value: any): value is number {
    return typeof value === 'number';
  }

  public static isDayHObject(value: Object): value is Object {
    return typeof value === 'object' && value !== null && 'D' in value && 'N' in value;
  }

  public static assigningConsumableAsset = {
    bodyAsConsumable: (body: any) => ({
      AssignedTo: body.AssignedTo,
      Quantity: body.Quantity,

      SerialNumber: body.SerialNumber,
      Remarks: body.Remarks,
    }),
  }

  /**
   * Desctuct a Date to Array of values in numbers
   * @param value 
   * @returns [ Year, Month, Date, Day, Hour, Min, Sec ]
   */
  public static dateDesctruct(value?: string | Date | null) {
    if (!value) return [];
    const date = new Date(value);
    return [date.getFullYear(), date.getMonth() + 1, date.getDate(), date.getDay(), date.getHours(), date.getMinutes(), date.getSeconds()];
  }






  /**
   * @deprecated The method should not be used.
   */
  public static User_IsClient(): boolean {
    return (Helper.UserLoginData().Designation != undefined || Helper.UserLoginData().Designation != null) && !Helper.isNumeric(Helper.UserLoginData().Designation);
  }

  /**
   * @deprecated The method should not be used.
   */
  public static FormValidator(form: FormGroup, controlNames: String[], condition: Function) {
    const errors: String[] = [];
    for (const control in form.controls) {
      const val = form.controls[control];
      if (controlNames.includes(control) && condition(val.value)) errors.push(control);
    }
    return errors;
  }

  /**
   * Get the `file extension` of the gicen file
   */
  public static GetFileExtension(file?: File | null): string {
    if (!file) return '';
    return file.name.split(".")[1];
  }

  /**
   * Get the `file Name` of the gicen file
   */
  public static GetFileName(file: File): string {
    return file.name.split(".")[0];
  }

  /**
   *Round off a number.
   @param value number to roun off
   @param decimal number of decimal places (default is 2)
   */
  public static RoundOff(value: number, decimals: number = 2): number {
    return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);
  }


  public static toFixedDecimals(value: number, decimals: number) {
    return Number(value).toFixed(decimals);
  }

  /**
   * Get the `number of days` in the given `month` and `year`
   */
  public static GetNumberDays(month: number, year: number): number {
    // Check for invalid month
    if (month < 1 || month > 12) {
      return -1;
    }

    // CHeck if leap year
    const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

    // Set the number of days based on month and leap year status
    const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (month === 2 && isLeapYear) {
      return 29; // February has 29 days in a leap year
    } else {
      return daysInMonth[month - 1];
    }
  }

  /**
   * Get the Month list `{ MonthNumber: number, MonthName: string }[]`
   */
  public static get getMonthList(): { number: number, name: string }[] {
    return [
      { number: 1, name: "January" },
      { number: 2, name: "February" },
      { number: 3, name: "March" },
      { number: 4, name: "April" },
      { number: 5, name: "May" },
      { number: 6, name: "June" },
      { number: 7, name: "July" },
      { number: 8, name: "August" },
      { number: 9, name: "September" },
      { number: 10, name: "October" },
      { number: 11, name: "November" },
      { number: 12, name: "December" },
    ];
  }

  /**
   * Get the age of the given `Date of birth`
   */
  public static GetAge(dob: Date): number {
    const today: Date = new Date();
    let age: number = today.getFullYear() - dob.getFullYear();
    var m: number = today.getMonth() - dob.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) age--;
    return age;
  }

  public static newDate(year?: number, month?: number, date?: number) {
    const nDate = new Date();
    if (year) nDate.setFullYear(year);
    if (month) nDate.setMonth(month - 1);
    if (date) nDate.setDate(date);
    return nDate;
  }

  /**
   * Format date:
   * `1 -> y-m-d`,
   * `2 -> y-d-m`,
   * `3 -> d-m-y`,
   * `4 -> m-d-y`,
   */
  public static FormatDate(raw_date?: Date | string | null, format: number = 1, seperator: string = '-'): string {
    if (!raw_date) return '';

    const date = new Date(raw_date);
    if (date.toString() == 'Invalid Date') return '';

    let y: number = date.getFullYear();
    let tm: number = date.getMonth() + 1;
    let td: number = date.getDate();

    let m: string = String(tm);
    let d: string = String(td);

    if (tm < 10) m = `0${tm}`;
    if (td < 10) d = `0${td}`;

    switch (format) {
      case 1: return `${y}${seperator}${m}${seperator}${d}`;
      case 2: return `${y}${seperator}${d}${seperator}${m}`;
      case 3: return `${d}${seperator}${m}${seperator}${y}`;
      case 4: return `${m}${seperator}${d}${seperator}${y}`;
      default: return '';
    }
  }

  /**
  * Returns String with format `mm`/`dd`/`yyyy`
  */
  public static ToInputDate(date?: string | Date): string {
    if (!date) return '';
    if (typeof date === 'string') {
      if (isNaN(Number(new Date(date)))) return '';
      return this.to_InputDate(new Date(date));
    }
    else return this.to_InputDate(date)
  }

  /**
   * Check if the given day is `sat` or `sun` hence `rest day`
   */
  public static isRestDay(date: Date): boolean {
    const dayOfWeek = date.getDay();
    return dayOfWeek === 6 || dayOfWeek === 0;
  }

  /**
   * Get the DateToday
   */
  public static GetDateToday(): DateTodayInterface {
    const today: Date = new Date();
    today.setHours(0, 0, 0, 0);
    const dateToday: DateTodayInterface = { day: -1, month: -1, year: -1, noDays: -1, monthName: '' };
    dateToday.day = today.getDate();
    dateToday.month = today.getMonth() + 1;
    dateToday.year = today.getFullYear();
    dateToday.noDays = Helper.GetNumberDays(dateToday.month, dateToday.year);
    // dateToday.monthName = Helper.GetMonthName(dateToday.month);
    dateToday.monthName = Helper.getMonthList[dateToday.month].name;
    return dateToday;
  }

  /**
   * Check if a date is between two dates
   */
  public static DateIsBetween2Dates(checkDate: Date, date1: Date, date2: Date): boolean {
    Helper.set_HrMinSecMili_toZero(checkDate);
    Helper.set_HrMinSecMili_toZero(date1);
    Helper.set_HrMinSecMili_toZero(date2);
    if (date1 > date2) [date1, date2] = [date2, date1];
    return checkDate.getTime() >= date1.getTime() && checkDate.getTime() <= date2.getTime();
  }

  public static GetDayName(dateString: string) {

    // Get the day of the week (0 for Sunday, 6 for Saturday)
    const day = new Date(dateString).getDay();

    // Array of day names
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    // Return the day name using the index
    return days[day];
  }

  /**
   * Get Name of Day in Week range `(0, 6)`.
   */
  public static GetDayNameByIndex(index: number) {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[index];
  }

  /**
   * Returns instance of `Date`
   */
  public static ToDate(date: string): Date {
    if (!date) return new Date();
    return new Date(date);

    if (date === '') return new Date();
    if (date.length < 10) return new Date();
    let temp_date!: Array<string>;
    let y: string = '';
    let m: string = '';
    let d: string = '';

    if (date.includes("-")) {
      temp_date = date.substring(0, 10).split('-');
      y = temp_date[0];
      m = temp_date[1];
      d = temp_date[2];
    } else if (date.includes("/")) {
      temp_date = date.substring(0, 10).split('/');
      y = temp_date[2];
      m = temp_date[0];
      d = temp_date[1];
    } else {
      return new Date(date);
    }

    return new Date(Number(y), Number(m) - 1, Number(d));
  }

  /**
   * Returns String of detailed date
   */
  public static ToDetailedDate(date: string | Date): string {
    if (!date) return '';
    if (typeof date === 'string') return this.ToDate(date).toDateString();
    else return date.toDateString();
  }


  public static splitCanvas(canvas: HTMLCanvasElement, maxHeight: number) {
    const canvasHeight = canvas.height;
    const canvasWidth = canvas.width;
    let currentY = 0;
    const canvases = [];

    while (currentY < canvasHeight) {
      const sliceHeight = Math.min(maxHeight, canvasHeight - currentY);

      const newCanvas = document.createElement('canvas');
      newCanvas.width = canvasWidth;
      newCanvas.height = sliceHeight;
      const newContext = newCanvas.getContext('2d');

      if (!newContext) {
        console.error("Error splitting canvas.")
        return [];
      }

      newContext.drawImage(canvas, 0, currentY, canvasWidth, sliceHeight, 0, 0, canvasWidth, sliceHeight);
      canvases.push(newCanvas);
      currentY += sliceHeight;
    }

    return canvases;
  }

  /**
   * Converts Object into URL params in string.
   * @param baseURL - your URL.
   * @param body  - Object that will be converted into search parameters.
   * @returns converted string (conmination of URL and Params).
   */
  public static ToURLParams(baseURL: string, body: Record<string, string>): string {
    const params = new URLSearchParams(body);
    return `${baseURL}?${params.toString()}`;
  }


  public static replaceAll(str: string, wordToReplace: string, replacementWord: string) {
    const escapedWord = wordToReplace.replace(/([.*+?^=!:${}()|[\]\\])/g, '\\$1');
    const regex = new RegExp(`\\b${escapedWord}\\b`, 'g');
    return str.replace(regex, replacementWord);
  }

  /**
   * Convert array into string. Instead of "1,2,3", you get "1, 2, 3".
   */
  public static arrayToProperString(array: any[]) {
    return Helper.replaceAll(array.toString(), ',', ', ');
  }


  public static sort = {
    ascendingAlphabet: (a: string, b: string) => a.localeCompare(b),
    descendingAlphabet: (a: string, b: string) => b.localeCompare(a),

    ascendingNumeric: (a: number, b: number) => a - b,
    descendingNumeric: (a: number, b: number) => b - a,

    ascendingDate: (a: Date | string, b: Date | string) => new Date(a).getTime() - new Date(b).getTime(),
    descendingDate: (a: Date | string, b: Date | string) => new Date(b).getTime() - new Date(a).getTime(),
  }

  public static assets = {
    sortByItemStatus: (a: PurchaseRequestInterface, b: PurchaseRequestInterface) => {
      const transformRequesDateIsToday = (requestDate: string) => {
        const rDate = new Date(requestDate);
        rDate.setHours(0, 0, 0, 0);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return rDate.getTime() == today.getTime();
      }

      const statusOrder: any = {
        'New': 0,
        'Pending': 1,
        'Approved': 2,
        'Disapproved': 3
      };

      const dateA = new Date(a.RequestedBy.RequestedAt);
      const dateB = new Date(b.RequestedBy.RequestedAt);

      const statusCompare = (statusOrder[a.Status] || 4) - (statusOrder[b.Status] || 4);
      if (statusCompare !== 0) return statusCompare;

      if (isNaN(dateA.getTime()) && isNaN(dateB.getTime())) return 0;
      if (isNaN(dateA.getTime())) return 1;
      if (isNaN(dateB.getTime())) return -1;

      if (transformRequesDateIsToday(a.RequestedBy.RequestedAt)) return -1;
      if (transformRequesDateIsToday(b.RequestedBy.RequestedAt)) return 1;

      return dateB.getTime() - dateA.getTime();
    }
  }

  public static isFutureDate(dateToCheck: string | Date) {
    const checkDate = new Date(dateToCheck);
    return checkDate.getTime() > Helper.dateToday.getTime();
  }

  public static toReadableDate(improperDate?: string | Date | null) {
    if (!improperDate) return '';
    const [date, time, mode, day] = String(improperDate).split(" ");
    return new Date(`${date} ${time ?? ''} ${mode ?? ''}`).toString()
  }

  public static numberToWords(value: number): string {
    const ones: any = {
      0: "zero",
      1: "one",
      2: "two",
      3: "three",
      4: "four",
      5: "five",
      6: "six",
      7: "seven",
      8: "eight",
      9: "nine",
    };

    const teens: any = {
      10: "ten",
      11: "eleven",
      12: "twelve",
      13: "thirteen",
      14: "fourteen",
      15: "fifteen",
      16: "sixteen",
      17: "seventeen",
      18: "eighteen",
      19: "nineteen",
    };

    const tens: any = {
      20: "twenty",
      30: "thirty",
      40: "forty",
      50: "fifty",
      60: "sixty",
      70: "seventy",
      80: "eighty",
      90: "ninety",
    };

    const largeNumbers: any = {
      100: "hundred",
      1000: "thousand",
      1000000: "million",
      1000000000: "billion",
    };

    if (value === 0) return ones[0];

    function convertHundreds(num: number): string {
      let result = "";

      if (num >= 100) {
        result += ones[Math.floor(num / 100)] + " " + largeNumbers[100];
        num %= 100;
        if (num > 0) result += " ";
      }

      if (num >= 20) {
        result += tens[Math.floor(num / 10) * 10];
        num %= 10;
        if (num > 0) result += "-" + ones[num];
      } else if (num >= 10) {
        result += teens[num];
      } else if (num > 0) {
        result += ones[num];
      }

      return result;
    }

    function convertDecimal(decimal: number): string {
      if (decimal < 10) {
        return ones[decimal];
      } else if (decimal < 20) {
        return teens[decimal];
      } else {
        const tensPlace = Math.floor(decimal / 10) * 10;
        const onesPlace = decimal % 10;
        return onesPlace ? `${tens[tensPlace]}-${ones[onesPlace]}` : tens[tensPlace];
      }
    }

    let [integerPart, decimalPart] = String(value).split('.').map(Number);

    let result = "";
    if (integerPart >= 1000000) {
      result += convertHundreds(Math.floor(integerPart / 1000000)) + " " + largeNumbers[1000000];
      integerPart %= 1000000;
      if (integerPart > 0) result += ", ";
    }

    if (integerPart >= 1000) {
      result += convertHundreds(Math.floor(integerPart / 1000)) + " " + largeNumbers[1000];
      integerPart %= 1000;
      if (integerPart > 0) result += ", ";
    }

    if (integerPart > 0) {
      result += convertHundreds(integerPart);
    }

    // Convert the decimal part if it exists
    if (decimalPart !== undefined) {
      result += " and " + convertDecimal(decimalPart);
    }

    return result.trim();
  }

  public static readonly reactiveForms = {
    control: <T>(value?: T | null, validators?: ValidatorFn[], asyncValidators?: AsyncValidatorFn[]) => {
      return new FormControl<T | undefined | null>(value, { validators: validators ?? [], asyncValidators: asyncValidators ?? [] })
    },
    array: <T extends AbstractControl>(value: T[]) => {
      return new FormArray<T>(value);
    },
    group: <T extends AbstractControl>(value: { [K in keyof T]: AbstractControl<any, any>; }) => {
      return new FormGroup<{ [K in keyof T]: AbstractControl<any, any>; }>(value);
    }
  }

  public static getElementInArray<T>(array: T[], index: "first" | "last" | number) {
    if (index == "first") return array[0];
    if (index == "last") return array[array.length - 1];
    return array[index];
  }

  public static hasNumber(value: string): boolean {
    const regex = /\d/;
    return regex.test(value);
  }

  public static hasCapitalLetter(value: string): boolean {
    const regex = /[A-Z]/;
    return regex.test(value);
  }

  public static hasLowercaseLetter(value: string): boolean {
    const regex = /[a-z]/;
    return regex.test(value);
  }

  public static number = {
    makeArray: (from: number, to: number): number[] => {
      if (from > to) return [];
      return Array(to - from + 1).fill(0).map((_, i) => from + i);
    },
  }























  /**
   * Malfunctioning... DO NOT USE!!!
   * @deprecated The method should not be used.
   */
  public static GetDaysFrom2Dates(date1: Date, date2: Date): number[] {
    // Ensure date1 is before date2
    if (date1 > date2) {
      [date1, date2] = [date2, date1];
    }

    // Calculate the difference in days
    const daysDiff = Math.floor((date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24));

    // Create an array of days between date1 and date2
    const days: number[] = [];
    for (let i = 0; i <= daysDiff; i++) {
      days.push(date1.getDate() + i);
    }

    return days;
  }

  /**
   * Perform `addition` or `subtraction` to date
   * @deprecated The method should not be used.
   */
  public static Date_Arithmetic(currDate: Date, arithmentic: ArithmenticDateInterface): Date {
    if (arithmentic.year) currDate.setFullYear(currDate.getFullYear() + arithmentic.year);
    if (arithmentic.month) currDate.setMonth(currDate.getMonth() + arithmentic.month);
    if (arithmentic.day) currDate.setDate(currDate.getDate() + arithmentic.day);
    return currDate;
  }

  /**
   *  Get `Designation Name` from the list of designations
   * @deprecated The method should not be used.
   */
  public static GetDesignationById(desID: string, desList: Array<DesignationInterface>): string {
    for (const val of desList) if (String(val.ID) === String(desID)) return val.Name;
    return '';
  }

  /**
   * @deprecated The method should not be used.
   */
  public static User_isApplicant(designation_id: number | undefined): boolean {
    return Number(designation_id) === 11;
  }

  /**
   * @deprecated The method should not be used.
   */
  public static User_isRecruitmentStaff(designation_id: number | undefined): boolean {
    return Number(designation_id) === 13;
  }


  // ***************************
  // *        PRIVATES         *
  // ***************************

  private static to_DetailedDate(date: string): string {
    if (date === '') return 'Not Valid';
    if (date.length < 10) return 'Not Valid';

    let temp_date!: Array<string>;
    let y: string = '';
    let m: string = '';
    let d: string = '';

    if (date.includes("-")) {
      temp_date = date.substring(0, 10).split('-');
      y = temp_date[0];
      m = temp_date[1];
      d = temp_date[2];
    } else if (date.includes("/")) {
      temp_date = date.substring(0, 10).split('/');
      y = temp_date[2];
      m = temp_date[0];
      d = temp_date[1];
    } else {
      return 'Not Valid';
    }

    return new Date(Number(y), Number(m) - 1, Number(d)).toDateString()
  }

  private static to_InputDate(date: Date): string {
    if (!date) return '';

    let y: number = date.getFullYear();
    let tm: number = date.getMonth() + 1;
    let td: number = date.getDate();

    let m: string = String(tm);
    let d: string = String(td);

    if (tm < 10) m = `0${tm}`;
    if (td < 10) d = `0${td}`;

    return `${m}/${d}/${y}`;
  }

  private static set_HrMinSecMili_toZero(date: Date): Date {
    date.setHours(0, 0, 0, 0);
    return date;
  }
}
